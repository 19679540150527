
import $ from 'jquery';
import { TweenMax, Power1 } from "gsap";
import DataTable from 'datatables.net';
import 'select2';                       // globally assign select2 fn to $ element
import 'select2/dist/css/select2.css';

function animateVibration(blockDiv) {
  var animationDuration = 0.5;
  var flipStrength = 360; // Angle de rotation pour le flip (ajustez selon vos besoins)

  // Anime le bloc div pour effectuer un flip une seule fois
  TweenMax.to(blockDiv, animationDuration, {
      rotationY: flipStrength, // Rotation autour de l'axe Y pour le flip
      ease: Power1.easeInOut, // Accélération pour un effet réaliste
      onComplete: function() {
          // Réinitialise la rotation du bloc div à sa valeur initiale
          TweenMax.set(blockDiv, { rotationY: 0 });
      }
  });
}


$(document).ready(function(){
    if ( $('.cms-id-8 .PM_ASSubmitSearch4').length > 0){
    $( ".PM_ASSubmitSearch4" ).click(function() {
        $('#PM_ASForm_4').submit(function(eventSearch4){
            return false;
            eventSearch4.preventDefault();
        });
        //var convertUrlSearch1To3 = (window.location.href).replace("s-4/","s-3/");
        //window.location.replace(convertUrlSearch1To3);
       });
    }
   
     $('.cbp-category-title').hover(function() {
       var thumbUrl = $(this).data('thumb');
       $('li#cbp-hrmenu-tab-17 .cbp-hrsub-inner').css('background-image', 'url(' + thumbUrl + ')');
     }, function() {
       // RÃ©tablir l'image par dÃ©faut du background lorsque le survol est terminÃ©
       $('li#cbp-hrmenu-tab-17 .cbp-hrsub-inner').css('background-image', 'url(/img/cms/menu/menu-general-empty.jpg)');
     });
   /**$('#search_filter_toggler_custom').click(function() {
       console.log('test');
       $('#filterModal .modal-body').html($('#PM_ASBlockOutput_3').html());
       $('#filterModal').modal('show');
     });**/
   $('#PM_ASCriterionGroupSelect_3_20').change(function() {
                if ($(this).val() === '') {
                    $('select#PM_ASCriterionGroupSelect_3_19 option:first-child, select#PM_ASCriterionGroupSelect_3_18 option:first-child').attr('selected', 'selected');
                    $('select#PM_ASCriterionGroupSelect_3_19 option:first-child, select#PM_ASCriterionGroupSelect_3_18 option:first-child').parent('select').trigger('change');
                }
            });

  // SÃ©lectionnez le bouton par sa classe et ajoutez l'attribut aria-label
  $("button.js-touchspin").attr("aria-label", "Qty choice");
  $("input.input-qty").attr("aria-label", "Qty input");
  $("nav#cbp-hrmenu > ul > li#cbp-hrmenu-tab-12 > a.nav-link").attr("aria-label", "Home Link");
  $("div.elementor-widget-container > div.elementor-image-box-wrapper > figure.elementor-image-box-img > a").attr("aria-label", "Engine mecanautik category Link");
  $("div.js-product-miniature-wrapper > article.product-miniature > div.product-description > a").attr("aria-label", "Engine parts product");
  $("div.thumbnail-container > div.product-functional-buttons > div.product-functional-buttons-links > a.btn-iqitwishlist-add").attr("aria-label", "Engine parts wishlist");
  $("div.thumbnail-container > div.product-functional-buttons > div.product-functional-buttons-links > a.js-quick-view-iqit").attr("aria-label", "Engine parts views");
  $("div.block-content > ul.social-links > li > a").attr("aria-label", "Social link mecanautik");

  //animation home page application button
  // Sélectionnez votre block div
var blockDiv = document.querySelector('.elementor-element-b0g01fq');

// Sélectionnez votre bouton
var bouton = document.querySelector('.cbp-hrmenu-tab-39');

// Ajoutez un écouteur d'événements sur le bouton
bouton.addEventListener('click', function() {
    // Appelez la fonction pour lancer l'animation lorsque le bouton est cliqué
    animateVibration(blockDiv);
    setTimeout(function() {
      TweenMax.killTweensOf(blockDiv);
  }, 3000);
});               
   
  if ( $('.motors-adapt-js').length > 0){

    var lang = $('html').attr('lang');

    var transLengthMenu = {
      'fr' : {'lengthMenu': [5, 10, 25, 50, 'Tous']},
      'en' : {'lengthMenu': [5, 10, 25, 50, 'All']},
      'es' : {'lengthMenu': [5, 10, 25, 50, 'Todos']},
    }
      

    // Définissez les traductions pour chaque langue
    var translations = {
      'fr': {
        info: 'Affichage de la page _PAGE_ sur _PAGES_',
        infoEmpty: 'Aucun enregistrement disponible',
        infoFiltered: '(filtré parmi _MAX_ enregistrements au total)',
        lengthMenu: 'Afficher _MENU_ enregistrements par page',
        zeroRecords: 'Aucun résultat trouvé - désolé',
        search: 'Recherche:',
        paginate: {
          "first": "Premier",
          "last": "Dernier",
          "next": "Suivant",
          "previous": "Précédent"
        },						
      },
      'en': {
        info: 'Showing page _PAGE_ of _PAGES_',
        infoEmpty: 'No records available',
        infoFiltered: '(filtered from _MAX_ total records)',
        lengthMenu: 'Display _MENU_ records per page',
        zeroRecords: 'Nothing found - sorry',
        search: 'Search:',
        paginate: {
          "first":      "First",
          "last":       "Last",
          "next":       "Next",
          "previous":   "Previous"
        },
      },
      'es': {
        info: 'Mostrando página _PAGE_ de _PAGES_',
        infoEmpty: 'No hay registros disponibles',
        infoFiltered: '(filtrado de un total de _MAX_ registros)',
        lengthMenu: 'Mostrar _MENU_ registros por página',
        zeroRecords: 'No se encontraron resultados - lo siento',
        search: 'Búsqueda:',
        paginate: {
          "first": "Primero",
          "last": "Último",
          "next": "Siguiente",
          "previous": "Anterior"
        },							
      }
    };

    // Utilisez la traduction correspondante à la langue détectée
    var engine_table = new DataTable('.motors-adapt-js', {
      language: translations[lang] || translations['en'],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        (transLengthMenu[lang]['lengthMenu'] || transLengthMenu['en']['lengthMenu'])
      ],
      searching: true
    });

    
    $('.FilterModel').select2({
        allowClear: true,
        width: '100%'
    }).on('change', function() {
        var selectedModel = $(this).val();
        // Filtrer le tableau DataTable en fonction de la sélection
        engine_table.column(2).search(selectedModel).draw();
    });
    
  }
  
});